module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CLLX3KKX3R"]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"antik","accessToken":"MC5YLWtsZUJBQUFDVUFmckxf.dO-_ve-_ve-_vXI277-9N--_vQHvv71f77-9de-_vTrvv71mQEwV77-9ZT7vv70z77-9Oj7vv71dZw","lang":"*","schemas":{"products":{"Main":{"uid":{"type":"UID","config":{"label":"slug namn"}},"sold":{"type":"Boolean","config":{"placeholder_false":"på lager","placeholder_true":"såld","default_value":false,"label":"sold"}},"name":{"type":"StructuredText","config":{"single":"heading1","label":"Produktnamn","placeholder":"Produktnamn"}},"description":{"type":"StructuredText","config":{"multi":"paragraph, heading4, heading5, heading6, strong, em, hyperlink, list-item","label":"Produkt beskrivning","placeholder":"Produkt beskrivning"}},"proveniens":{"type":"StructuredText","config":{"multi":"paragraph, strong","label":"Proveniens"}},"category":{"type":"Select","config":{"options":["Belysning","Möbler","Konsthantverk","Konst","Silver","Diverse"],"label":"Produkt kategori"}},"price":{"type":"Number","config":{"label":"Produkt pris"}},"product_images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Produkt bild"}}},"label":"Produkt bild(er)"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
