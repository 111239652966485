// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategori-belysning-js": () => import("./../../../src/pages/kategori/belysning.js" /* webpackChunkName: "component---src-pages-kategori-belysning-js" */),
  "component---src-pages-kategori-diverse-js": () => import("./../../../src/pages/kategori/diverse.js" /* webpackChunkName: "component---src-pages-kategori-diverse-js" */),
  "component---src-pages-kategori-konst-js": () => import("./../../../src/pages/kategori/konst.js" /* webpackChunkName: "component---src-pages-kategori-konst-js" */),
  "component---src-pages-kategori-konsthantverk-js": () => import("./../../../src/pages/kategori/konsthantverk.js" /* webpackChunkName: "component---src-pages-kategori-konsthantverk-js" */),
  "component---src-pages-kategori-mobler-js": () => import("./../../../src/pages/kategori/möbler.js" /* webpackChunkName: "component---src-pages-kategori-mobler-js" */),
  "component---src-pages-kategori-silver-js": () => import("./../../../src/pages/kategori/silver.js" /* webpackChunkName: "component---src-pages-kategori-silver-js" */),
  "component---src-pages-kontaktinformation-js": () => import("./../../../src/pages/kontaktinformation.js" /* webpackChunkName: "component---src-pages-kontaktinformation-js" */),
  "component---src-pages-omoss-js": () => import("./../../../src/pages/omoss.js" /* webpackChunkName: "component---src-pages-omoss-js" */),
  "component---src-pages-sortiment-js": () => import("./../../../src/pages/sortiment.js" /* webpackChunkName: "component---src-pages-sortiment-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

